import React from "react"
import tw from "twin.macro"
import { Link } from "gatsby"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import Section from "@components/section"
import { sectionIh } from "@styles/ui"
import { ihSitemap } from "@utils/sitemapData"

// Assets
import logo from "@images/logos/xywav-logo.svg"

const SiteMap = ({ location }) => {
  return (
    <Layout
      location={location}
      isiBgColour="white"
      footerBgColour="white"
      headerType="ih"
      footerType="ih"
      mainBgColour="lilac"
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals, Inc.{" "}
          <nobr>US-XYW-2200433 Rev1124</nobr>
        </>
      }
    >
      <Seo
        title="User Sitemap | XYWAV® IH"
        description="Explore the XYWAV® (calcium, magnesium, potassium, and sodium oxybates) user sitemap. See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />

      <section tw="relative py-12 pt-20 md:(pt-36) xl:(pt-44 pb-16)">
        <img
          src={logo}
          width="630px"
          alt=""
          tw="hidden xl:(block z-[1] absolute right-[-100px] top-[80px])"
        />
        <Container isIh>
          <h1
            css={[
              tw`text-[1.5625rem] text-studio font-ffcocon font-bold leading-[1.28] lg:(text-[3rem] leading-[1.15])`,
            ]}
          >
            Sitemap
          </h1>
        </Container>
      </section>
      <Section addedStyles={[sectionIh]} bgColour={tw`bg-lilac`}>
        <Container isIh>
          {ihSitemap.map((section, i) => {
            return (
              <>
                {section.url ? (
                  <Link
                    to={section.url}
                    tw="block text-base font-extrabold underline mb-6 lg:(text-xl)"
                  >
                    <h2>{section.text}</h2>
                  </Link>
                ) : (
                  <h2 key={i} tw="text-base font-extrabold mb-6 lg:(text-xl)">
                    {section.text}
                  </h2>
                )}
                {section.subLinks &&
                  section.subLinks.map((link, j) => {
                    return (
                      <Link
                        key={j}
                        to={link.url}
                        tw="text-base font-light block mb-6 pl-6 underline lg:(text-xl) hover:(no-underline)"
                      >
                        {link.text}
                      </Link>
                    )
                  })}
              </>
            )
          })}
        </Container>
      </Section>
    </Layout>
  )
}

export default SiteMap
